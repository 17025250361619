import { ErrorBoundary, Paging, SearchBox } from '@elastic/react-search-ui'
import {
	Button,
	ErrorMessage,
	Heading,
	InputView,
	Loading,
	Options,
} from 'components/common'
import { Col, Row } from 'react-bootstrap'
import { useState } from 'react'
import { getCodeSystemOid, paths, scrollToTop } from 'utils'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { SearchPageLayoutProps } from './interface'
import { ScrollWrapper, Wrapper } from './style'
import { useLocation } from 'react-router-dom'
import { useStoreContext } from 'context/StoreProvider'
import { SearchSectionWrapper } from '../../SearchWithFilter/style'
import { isMobile } from 'react-device-detect'
import MetaInfoBar from './MetaInfoBar'
import { SidebarContent } from '../../SearchWithFilter/common/SearchResultLayout/style'

const SearchPageLayout = ({
	headingLabel,
	sidebarContent,
	isLoading,
	results,
	bodyResults,
	searchTerm,
}: SearchPageLayoutProps) => {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	// why did I have to change this
	const [showFilter, setShowFilter] = useState<boolean>(
		isMobile || window.innerWidth > 992 ? true : false
	)
	const { state } = useStoreContext()
	const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false)

	const { codeSystem } = state

	const isResult = pathname.startsWith(paths.result)

	const noResultsAfterSearch = (results as string[]).length > 0

	const updateWindowDimensions = () => {
		setIsSmallScreen(isMobile || window.innerWidth < 992)
		setShowFilter(true)
	}

	window.addEventListener('resize', updateWindowDimensions)

	return (
		<Wrapper>
			<SearchSectionWrapper id='search-wrapper' className='pb-2'>
				<Row className='p-0 align-content-center align-items-end justify-content-between flex-column-reverse flex-sm-row gap-2 pt-2'>
					<Col
						as={'label'}
						className='p-0'
						id={`${codeSystem}-search-label`}
						htmlFor={`${codeSystem}-search-input`}
					>
						<Heading
							variant='h1'
							className='fs-4 fw-bold fs-md-3 mb-2'
							id={`${codeSystem}-page-title`}
						>
							{headingLabel}{' '}
							{getCodeSystemOid(codeSystem as string) !== '' && (
								<span style={{ color: 'gray', fontWeight: 'normal' }}>
									({getCodeSystemOid(codeSystem as string)})
								</span>
							)}
						</Heading>
					</Col>
					{!isResult && (
						<Col xs='auto' className='p-0'>
							<Options />
						</Col>
					)}
				</Row>

				<ErrorBoundary>
					<SearchBox
						searchAsYouType
						autocompleteResults={false}
						autocompleteSuggestions={false}
						shouldClearFilters={false}
						debounceLength={500}
						inputView={InputView}
					/>
				</ErrorBoundary>
			</SearchSectionWrapper>
			<div className='row-lg-cols-2 position-relative row-lg-nowrap'>
				<SidebarContent $size='lg'>
					<Row className='row-lg-cols-2 gap-3 gap-lg-0 pt-2 pt-lg-0'>
						{isSmallScreen && (
							<Button
								variant='tertiary'
								icon={!showFilter ? faArrowDown : faArrowUp}
								className='justify-content-between d-flex mt-2 mt-lg-0 w-100'
								size='md'
								iconright
								color='grey'
								onClick={() => setShowFilter(!showFilter)}
								textColor='black'
							>
								{!showFilter
									? t('nlkPage.filter.showFilter')
									: t('nlkPage.filter.hideFilter')}
							</Button>
						)}

						{showFilter && (
							<SidebarContent
								$size='lg'
								className={isSmallScreen ? '' : 'position-sticky top-0'}
							>
								{sidebarContent}
							</SidebarContent>
						)}

						<Col className='p-0' lg={9} id='search-results'>
							{!isLoading && <MetaInfoBar />}
							{isLoading ? (
								<Loading />
							) : noResultsAfterSearch ? (
								<>
									<ScrollWrapper>{bodyResults}</ScrollWrapper>

									{searchTerm && !isLoading && (
										<Row onClick={scrollToTop} className='py-3'>
											<Paging />
										</Row>
									)}
								</>
							) : (
								<ErrorMessage
									noResults
									searchTerm={searchTerm}
									className='mt-5'
								/>
							)}
						</Col>
					</Row>
				</SidebarContent>
			</div>
		</Wrapper>
	)
}

export default SearchPageLayout
