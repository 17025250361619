import type { SearchResult } from '@elastic/search-ui/lib/esm/types'
import { Col } from 'react-bootstrap'
import {
	chapterString,
	codesystems,
	collectionsString,
	getRaw,
	paths,
} from 'utils'
import {
	ResultViewWrapper,
	SearchResultBar,
	TableHeader,
	TableHeaderContainer,
} from './style'
import { useStoreContext } from 'context/StoreProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { handleNavigate } from '../../../../../utils'
import { useTranslation } from 'react-i18next'
import { withSearch } from '@elastic/react-search-ui'
import AdmResultView from './AdmResultView'
import ChapterResultView from './ChapterResultView'
import { isMobile } from 'react-device-detect'

const ResultView = ({ results, searchTerm, addFilter }: SearchResult) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { actions, state } = useStoreContext()
	const { codeSystem, excludedSearchFields } = state
	const { updateCodeValue } = actions
	const { pathname } = useLocation()
	const isAdm = pathname.startsWith(paths.adm)
	const isNorpat = pathname.startsWith(paths.norpat)
	const headerHeight = document.getElementById('site-header')?.clientHeight || 0
	const tabNavHeight =
		document.getElementById('codesystem-tab-nav')?.clientHeight || 0
	const searchWrapperHeight =
		document.getElementById('search-wrapper')?.clientHeight || 0
	const tabViewHeight =
		document.getElementById(`tab-view-${codeSystem}`)?.clientHeight || 0
	const metaBarHeight =
		document.getElementById('result-meta-bar')?.clientHeight || 0

	const calculateHeightClinical =
		headerHeight +
		tabNavHeight +
		searchWrapperHeight +
		tabViewHeight +
		metaBarHeight +
		4

	const calculateHeightMobile =
		headerHeight +
		tabNavHeight +
		searchWrapperHeight +
		metaBarHeight +
		tabViewHeight +
		4

	//TODO: clean up this retarded code

	const isMobileCondition = isMobile || window.innerWidth < 992

	return (
		<SearchResultBar $isAdm={isAdm} role='table' className='position-relative'>
			<TableHeaderContainer
				$topValue={`${
					isAdm
						? calculateHeightClinical - 2
						: isMobileCondition
						? calculateHeightMobile
						: isNorpat
						? calculateHeightClinical - 1
						: calculateHeightClinical
				}`}
				role='rowgroup'
				$isAdm={isAdm}
			>
				<TableHeader role='row' className='gap-1 gap-md-3 flex-nowrap'>
					{isAdm ? (
						<>
							<Col role='columnheader' className='p-0' xs={1}>
								<span>{t('general.id')}</span>
							</Col>
							<Col role='columnheader' xs={3} className='p-0'>
								<span>{t('general.codesystem')}</span>
							</Col>
							<Col role='columnheader' xs={2} className='p-0'>
								<span>{t('search.admTable.owner')}</span>
							</Col>
							<Col role='columnheader' xs={3} md={2} className='p-0'>
								<span>{t('search.admTable.collection')}</span>
							</Col>
							<Col role='columnheader' xs={2} className='p-0'>
								<span>{t('search.sorting.category')}</span>
							</Col>
							<Col role='columnheader' xs={1} className='text-end p-lg-0'>
								<span>{t('search.admTable.status')}</span>
							</Col>
						</>
					) : (
						<>
							<Col role='columnheader' xs='auto' className='p-0'>
								<span>{t('general.codeValue')}</span>
							</Col>
							<Col role='columnheader' xs='auto' className='p-0'>
								<span>{t('general.codeText')}</span>
							</Col>
						</>
					)}
				</TableHeader>
			</TableHeaderContainer>
			{results.map((result: SearchResult) => {
				const shouldBeDisabled =
					result.active.raw === 'false' &&
					result.code_system.raw !== codesystems.adm &&
					result.code_system.raw !== codesystems.norpat

				const handleClickedResult = () => {
					if (result.code_system.raw === codesystems.adm) {
						navigate(
							`${handleNavigate(result.code_system.raw)}/${collectionsString}/${
								result.compact_code.raw.split(' ')[0]
							}`
						)
						updateCodeValue(result.compact_code.raw.split(' ')[0])
					} else if (result.code_system.raw === codesystems.icpc2b) {
						navigate(
							`${handleNavigate(result.code_system.raw)}/${chapterString}/${
								result.parent_code.raw
							}`
						)
						updateCodeValue(result.parent_code.raw)
					} else {
						if (shouldBeDisabled) {
							return null
						} else {
							navigate(
								`${handleNavigate(
									result.code_system.raw
								)}/${chapterString}/${result.code.raw.replace('_', '')}`
							)
							updateCodeValue(result.code.raw)
						}
					}
				}

				return (
					<ResultViewWrapper
						$isDisabled={shouldBeDisabled}
						onClick={handleClickedResult}
						onKeyDown={(event) => {
							if (event.key === 'Enter' || event.key === ' ') {
								handleClickedResult()
							}
						}}
						action={!shouldBeDisabled}
						key={
							result.code_system.raw === codesystems.adm
								? getRaw(result, 'compact_code')?.substring(0, 4)
								: getRaw(result, 'code')
						}
						role='rowgroup'
						className='w-100'
					>
						{getRaw(result, 'code_system') === codesystems.adm ? (
							<AdmResultView
								result={result}
								searchTerm={searchTerm}
								showCodes={isAdm && !excludedSearchFields.includes('code')}
							/>
						) : (
							<ChapterResultView
								result={result}
								searchTerm={searchTerm}
								addFilter={addFilter}
							/>
						)}
					</ResultViewWrapper>
				)
			})}
		</SearchResultBar>
	)
}

export default withSearch(({ searchTerm, results, addFilter }) => ({
	searchTerm,
	results,
	addFilter,
}))(ResultView)
