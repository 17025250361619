export const maxInputLength = 100
export const autocompleteMinimalCharacters = 2
export const resultsPerPage = 20
export const chapterPageSize = 50
export const chapterString = 'chapter'
export const resultString = 'result'
export const searchString = 'search'
export const collectionsString = 'collections'
export const codeValueString = 'codeValue'
export const historyString = 'history'
