import { LinkSection, SearchSection } from 'components/pages/frontpage'

const MainPage = () => {
	return (
		<>
			{/* <SearchSection /> Uncomment and remove div below if global search is reinstated */}
			<div className='m-4'></div>
			<LinkSection />
		</>
	)
}

export default MainPage
