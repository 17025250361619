import { styled } from 'styled-components'
import { over } from 'utils'

export const Wrapper = styled.article<{
	$hierarchyLevel: number
	$activeCode: boolean
}>`
	margin-left: calc(
		5px * ${(props) => props.$hierarchyLevel}${(props) => (props.$activeCode ? ' - 1rem' : '')}
	);

	border-radius: ${(props) => (props.$activeCode ? '10px' : 'none')};
	border: ${(props) =>
		props.$activeCode
			? `1px solid ${props.theme.colours.primary.blue}`
			: 'none'};
	padding: ${(props) => (props.$activeCode ? '1rem' : '0')};

	${over('md')} {
		margin-left: calc(
			20px * ${(props) => props.$hierarchyLevel}${(props) => (props.$activeCode ? ' - 1rem' : '')}
		);
	}

	.heading-style {
		&:hover {
			color: ${(props) => props.theme.colours.primary.blue};
			text-decoration: underline;
		}
	}
`
