import { codesystemsEndpointName } from 'utils'

interface CodeHistory {
	[key: string]: string[]
}

export const historyService = {
	getHistory: (): CodeHistory => {
		const h: CodeHistory = JSON.parse(
			localStorage.getItem('code_visit_history') || '{}'
		)

		// Initialize history object with empty arrays for each code system
		if (Object.keys(h).length === 0) {
			for (const key in codesystemsEndpointName) {
				h[key] = []
			}

			localStorage.setItem('code_visit_history', JSON.stringify(h))
		}

		return h
	},

	getCodeSystemHistory: (codeSystem: string): string[] => {
		if (codeSystem === 'NLK') codeSystem = 'nlk'

		const history = historyService.getHistory()

		if (!history[codeSystem]) {
			throw new Error(`Code system ${codeSystem} does not exist`)
		}

		return history[codeSystem]
	},

	addToHistory: (codeSystem: string, code: string) => {
		if (codeSystem === 'NLK') codeSystem = 'nlk'
		const history = historyService.getHistory()

		if (!history[codeSystem]) {
			throw new Error(`Code system ${codeSystem} does not exist`)
		}
		if (history[codeSystem][0] !== code) {
			history[codeSystem].unshift(code)

			// Find and remove duplicates
			history[codeSystem] = history[codeSystem].filter(
				(value, index, self) => self.indexOf(value) === index
			)

			// Limit history to 100 items
			if (history[codeSystem].length > 100) {
				history[codeSystem] = history[codeSystem].slice(0, 100)
			}

			localStorage.setItem('code_visit_history', JSON.stringify(history))
		}
	},

	removeFromHistory: (codeSystem: string, code: string) => {
		if (codeSystem === 'NLK') codeSystem = 'nlk'
		const history = historyService.getHistory()

		if (!history[codeSystem]) {
			throw new Error(`Code system ${codeSystem} does not exist`)
		}

		const index = history[codeSystem].indexOf(code)
		if (index !== -1) {
			history[codeSystem].splice(index, 1)
			localStorage.setItem('code_visit_history', JSON.stringify(history))
		}
	},

	clearHistory: (codeSystem: string) => {
		if (codeSystem === 'NLK') codeSystem = 'nlk'
		const history = historyService.getHistory()

		if (!history[codeSystem]) {
			throw new Error(`Code system ${codeSystem} does not exist`)
		}

		history[codeSystem] = []
		localStorage.setItem('code_visit_history', JSON.stringify(history))
	},

	clearAllHistory: () => {
		localStorage.removeItem('code_visit_history')
	},
}
