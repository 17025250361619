import { HierarchyResponse } from './interface'
import { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from 'context/StoreProvider'
import { UseQueryResult, useQueries, useQuery } from '@tanstack/react-query'
import Node from './Node'
import { HierarchyWrapper } from './style'
import {
	chapterString,
	codesystemsEndpointName,
	fetchApi,
	getApiEndpoint,
} from 'utils'
import { ErrorMessage, Loading } from 'components/common'
import { useTranslation } from 'react-i18next'

// TODO: Make this component responsible for its own auto-scrolling to active element

const Hierarchy = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { state, actions } = useStoreContext()

	const { code, codeSystem, hierarchyScrollRef } = state
	const { updateCodeValue } = actions

	const [fetchError, setFetchError] = useState<string>('')

	const [expandedNodes, setExpandedNodes] = useState<string[]>([])

	const [hierarchy, setHierarchy] = useState<HierarchyResponse>()

	const fetchHierarchy = async (
		codeSystemValue: string,
		codeValue: string
	): Promise<HierarchyResponse> => {
		try {
			if (codeValue === undefined) codeValue = 'root'

			const res = await fetch(
				getApiEndpoint(codeSystemValue as string, codeValue, {
					useHierarchy: true,
					includeInactive: codeSystemValue === codesystemsEndpointName.norpat,
				})
			)

			// Non-404 errors
			if (!res.ok && res.status !== 404) {
				setFetchError(res.statusText)
			}

			return await res.json()
		} catch (err) {
			throw err
		}
	}

	const fetchChildren = async (
		codeSystemValue: string,
		codeValue: string
	): Promise<HierarchyResponse> => {
		try {
			const res = await fetch(
				getApiEndpoint(codeSystemValue as string, codeValue, {
					useChildren: true,
					includeInactive: codeSystemValue === codesystemsEndpointName.norpat,
				})
			)

			// Non-404 errors
			if (!res.ok && res.status !== 404) {
				setFetchError(res.statusText)
			}

			return await res.json()
		} catch (err) {
			throw err
		}
	}

	// Merge two hierarchies recursively (recursive tree merge)
	const mergeHierarchy = (
		currentHierarchy: HierarchyResponse,
		newHierarchy: HierarchyResponse
	) => {
		// Ensure same node for both hierarchies
		if (currentHierarchy.codeValue !== newHierarchy.codeValue) {
			throw new Error('mergeHierarchy(): Cannot merge two different nodes')
		}

		// No current children, use new children even if empty
		if (
			currentHierarchy.children === undefined ||
			currentHierarchy.children.length === 0
		) {
			currentHierarchy.children = newHierarchy.children
			return currentHierarchy
		}
		// No incoming children, keep current children
		else if (
			newHierarchy.children === undefined ||
			newHierarchy.children.length === 0
		) {
			return currentHierarchy
		}

		// Both have children, must be merged
		// Create a map of the current children
		const currentChildrenMap = new Map<string, HierarchyResponse>()
		currentHierarchy.children.forEach((child) => {
			currentChildrenMap.set(child.codeValue, child)
		})

		// Merge the new children into the current children
		newHierarchy.children.forEach((child) => {
			const currentChild = currentChildrenMap.get(child.codeValue)
			if (currentChild) {
				// Merge the current child with the new child
				mergeHierarchy(currentChild, child)
			} else {
				// Should never happen
				throw new Error(
					'mergeHierarchy(): Child not found in current hierarchy'
				)
			}
		})

		return currentHierarchy
	}

	// Recursive depth-first search for a node in the hierarchy
	const findInHierarchy = (
		node: HierarchyResponse,
		codeValue: string
	): HierarchyResponse | undefined => {
		if (node.codeValue === codeValue) {
			return node
		}

		if (node.children) {
			for (const child of node.children) {
				const found = findInHierarchy(child, codeValue)
				if (found) {
					return found
				}
			}
		}

		return undefined
	}

	// Fetch and merge hierarchy when code changes, and expand its path to root
	useEffect(() => {
		if (codeSystem === undefined) return

		// If code is undefined, fetch root hierarchy and remove any expanded nodes
		if (code === undefined) {
			fetchHierarchy(codeSystem, 'root').then((rootHierarchy) => {
				setHierarchy(() => {
					return rootHierarchy
				})
				setExpandedNodes([])
			})
		}

		// If code is defined, fetch hierarchy for the code and merge with existing hierarchy if it exists
		if (code !== undefined && codeSystem !== undefined) {
			fetchHierarchy(codeSystem, code).then((newCodeHierarchy) => {
				// Update hierarchy
				setHierarchy((prevHierarchy) => {
					if (prevHierarchy) {
						return { ...mergeHierarchy(prevHierarchy, newCodeHierarchy) }
					} else {
						return { ...newCodeHierarchy }
					}
				})
			})
		}
	}, [codeSystem, code])

	// When hierarchy updates, make sure the active code node is expanded
	useEffect(() => {
		// Find node item in hierarchy
		if (hierarchy) {
			if (code === undefined) return
			if (codeSystem === undefined) return

			const node = findInHierarchy(hierarchy, code)

			if (node) {
				// fetch path and children if not defined
				// path is needed to expand the necessary nodes
				// children is needed to render the children
				if (
					node.path === undefined ||
					(node.isLeafNode === false && node.children === undefined)
				) {
					fetchChildren(codeSystem, code)
						.then((newCode) => {
							// Update node children
							node.children = newCode.children
							node.path = newCode.path
						})
						.then(() => {
							// Expand path to root
							setExpandedNodes((prevExpandedNodes) => {
								let newExpandedNodes = [...prevExpandedNodes, ...node.path]

								// Remove duplicates
								newExpandedNodes = [...new Set(newExpandedNodes)]
								return newExpandedNodes
							})
						})
				}
			} else {
				console.log('HierarchyError: active code node not found in hierarchy')
			}
		}
	}, [hierarchy])

	// Scroll active node to top on select
	useLayoutEffect(() => {
		const scrollToElement = async () => {
			const hierarchyElement = hierarchyScrollRef.current[code as string]
			if (hierarchyElement) {
				hierarchyElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
			}
		}
		scrollToElement()
	}, [code, hierarchyScrollRef])

	const flipExpandedStateForItem = (theItem: HierarchyResponse) => {
		setExpandedNodes((prevExpandedNodes) => {
			if (prevExpandedNodes.includes(theItem.codeValue)) {
				// If the node is already expanded, remove it from expandedNodes
				return prevExpandedNodes.filter(
					(codeValue) => codeValue !== theItem.codeValue
				)
			} else {
				// If the node is not expanded, add it to expandedNodes
				const newExpandedNodes = [...prevExpandedNodes, theItem.codeValue]

				return newExpandedNodes
			}
		})
	}

	const handleExpand = (item: HierarchyResponse) => () => {
		// If item has children and they are not loaded in, fetch from API
		if (item.isLeafNode === false && item.children === undefined) {
			fetchChildren(codeSystem as string, item.codeValue)
				.then((fetchedCode) => {
					// Update node children
					item.children = fetchedCode.children
					item.path = fetchedCode.path
				})
				.then(() => {
					flipExpandedStateForItem(item)
				})
		} else {
			flipExpandedStateForItem(item)
		}
	}

	const handleNavigate = (item: HierarchyResponse) => () => {
		// If item is active, state change wont trigger, and expansion won't happen. If item is collapsed, expand it.
		if (
			item.codeValue === code && // item is active
			!expandedNodes.includes(item.codeValue) && // item is collapsed
			item.isLeafNode === false && // item has children
			item.children !== undefined // item's children are loaded
		) {
			return flipExpandedStateForItem(item)
		}

		updateCodeValue(item.codeValue as string)
		navigate(`${chapterString}/${item.codeValue}/`)
	}

	// Render the entire hierarchy tree recursively
	const renderItems = (items: HierarchyResponse[], level = 0) => {
		return (
			items &&
			items.map((item) => {
				return (
					<Fragment key={item.codeValue}>
						<Node
							item={item}
							level={level}
							isActive={code === item.codeValue}
							isExpanded={expandedNodes.includes(item.codeValue)}
							handleNavigate={handleNavigate(item)}
							handleExpand={handleExpand(item)}
							innerRef={(ref: HTMLDivElement | null) => {
								hierarchyScrollRef.current[item.codeValue] = ref
							}}
						>
							{expandedNodes.includes(item.codeValue) &&
								renderItems(item.children, level + 1)}
						</Node>
					</Fragment>
				)
			})
		)
	}

	return (
		<>
			{code ? (
				<button
					onClick={(e) => {
						e.preventDefault()
						const element = document.getElementById('chapter-content')
						if (element) {
							element.scrollIntoView()
							element.focus()
						}
					}}
					className='skip-link'
				>
					{t('accessibility.jumpToContent')}
				</button>
			) : null}
			{/* TODO: Make hierarchy a recursive element to make renderItems redundant and re-implement error and loading */}
			<HierarchyWrapper id={`${codeSystem}-hierarchy-tree`}>
				{/* {isLoading && <Loading />}
				{isError && <ErrorMessage errorResponse={fetchError} />} */}
				{renderItems(hierarchy?.children as HierarchyResponse[])}
			</HierarchyWrapper>
		</>
	)
}

export default Hierarchy
