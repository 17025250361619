import codesystem from 'utils/constants/codesystemsEndpointName'

/* Is used to get the correct headline for all search pages */

const codeSystemMap: Record<string, string> = {
	[codesystem.icd10]: '7110',
	[codesystem.icpc2]: '7170',
	[codesystem.icpc2b]: '7170',
	[codesystem.nkpk]: '7275',
	[codesystem.phbu]: '7112',
	[codesystem.norpat]: '7010',
	[codesystem.apat]: '7010',
	['NLK']: '7280',
	[codesystem.adm]: '',
	[codesystem.provemateriale]: '8351',
	[codesystem.anatomisk]: '8352',
	[codesystem.snomedct]: '',
	[codesystem.tekstligeresultatverdier]: '8340',
	[codesystem.undersokelsesmetode]: '8354',
}

const getCodeSystemOid = (value: string) => {
	return codeSystemMap[value]
}

export default getCodeSystemOid
