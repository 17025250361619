import { historyService } from 'context'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useStoreContext } from 'context/StoreProvider'
import {
	codesystemsEndpointName,
	historyString,
	collectionsString,
	endpoints,
} from 'utils'
import { Button, Row, TabContainer, Nav, Tab, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { CollectionResponse } from 'components/search/results/AdmResults/interface'
import { AdmResults } from 'components/search/results'
import { SidebarContent } from 'components/search/buckets/SearchWithFilter/common/SearchResultLayout/style'
import { isMobile } from 'react-device-detect'

const HistoryList = () => {
	const { view } = useParams()
	const { state } = useStoreContext()
	const { codeSystem } = state
	const navigate = useNavigate()

	const [history, setHistory] = useState<CollectionResponse[]>([])

	let smallScreen = isMobile || window.innerWidth < 992
	const updateWindowDimensions = () => {
		smallScreen = isMobile || window.innerWidth < 992
	}
	window.addEventListener('resize', updateWindowDimensions)

	const fetchCodeSystemInfo = async () => {
		try {
			const res = await fetch(endpoints.apiAdmCollectionsEndpointDev)

			if (!res.ok && res.status !== 404) {
				console.log(res.statusText)
			} else {
				const allCollections = await res.json()
				const allCodeLists = allCollections.flatMap(
					(entry: any) => entry.members
				)
				return allCodeLists
			}
		} catch (err) {
			throw err
		}
	}

	const isAdm = codeSystem === codesystemsEndpointName.adm

	useEffect(() => {
		if (
			!codeSystem ||
			view !== historyString ||
			codeSystem !== codesystemsEndpointName.adm
		)
			return

		const storedHistory = historyService.getCodeSystemHistory(codeSystem)

		if (!history) return
		// check if all stored already are in state
		if (
			storedHistory.every((c) => history.some((h) => h.id === c)) &&
			history.length === storedHistory.length
		) {
			history.sort((a: CollectionResponse, b: CollectionResponse) => {
				return storedHistory.indexOf(a.id) - storedHistory.indexOf(b.id)
			})
			return
		}

		fetchCodeSystemInfo().then((data) => {
			if (!data || !Array.isArray(data) || data.length === 0) return

			// ensure data is sorted the same as storedHistory
			data = data
				.filter((item: CollectionResponse) => storedHistory.includes(item.id))
				.sort((a: CollectionResponse, b: CollectionResponse) => {
					return storedHistory.indexOf(a.id) - storedHistory.indexOf(b.id)
				})

			setHistory(data)
		})
	}, [codeSystem, view])

	const removeCode = (code: string) => {
		historyService.removeFromHistory(codeSystem as string, code)
		setHistory(history.filter((c) => c.id !== code))
	}

	const [key, setKey] = useState('0')

	return history.length > 0 && codeSystem ? (
		<TabContainer activeKey={key} onSelect={(k) => setKey(k || '0')}>
			<Row>
				<SidebarContent $isAdm={isAdm} $size={'sm'}>
					<Nav>
						<Table striped hover className='p-0'>
							<thead>
								<tr>
									<th>Kodeverk</th>
									<th>Fjern</th>
								</tr>
							</thead>
							<tbody>
								{history.map((codesys: CollectionResponse, index: number) => (
									<tr
										onMouseEnter={() => setKey(`${index}`)}
										key={`history-list-item-${index}`}
									>
										<td
											onClick={() =>
												navigate(`${collectionsString}/${codesys.id}/`)
											}
											style={{ cursor: 'pointer' }}
										>
											<span style={{ fontWeight: 'bold' }}>{codesys.id}</span>{' '}
											{codesys.name}
										</td>
										<td>
											<Button
												variant='none'
												onClick={() => removeCode(codesys.id)}
											>
												<FontAwesomeIcon icon={faClose} />
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Nav>
				</SidebarContent>
				{!smallScreen && (
					<SidebarContent className={`d-flex `} $isAdm={isAdm}>
						<Tab.Content>
							{history.map((codesys: CollectionResponse, index: number) => (
								<Tab.Pane
									eventKey={`${index}`}
									key={`history-item-details-${index}`}
								>
									{key === `${index}` && (
										<AdmResults codeSystemId={codesys.id} />
									)}
								</Tab.Pane>
							))}
						</Tab.Content>
					</SidebarContent>
				)}
			</Row>
		</TabContainer>
	) : (
		<div>Ingen besøkte {isAdm ? 'kodeverk' : 'koder'}</div>
	)
}

export default HistoryList
