import { appendClassName } from 'utils'
import { useEffect, useState } from 'react'
import { useStoreContext } from 'context/StoreProvider'
import { Checkbox, FilterWrapper } from 'components/common/elastic/style'

type SearchFieldFilterProps = {
	className?: string
	label: string
	searchField: string
	removedWhenSelected: boolean
	initialState: boolean
}

const SearchFieldFilter = ({
	className,
	label,
	searchField,
	removedWhenSelected,
	initialState,
}: SearchFieldFilterProps) => {
	const { state, actions } = useStoreContext()
	const { excludedSearchFields } = state
	const { addExcludedSearchField, removeExcludedSearchField } = actions

	const [isSelected, setIsSelected] = useState<boolean>(initialState)

	const handleToggle = () => {
		if (isSelected) {
			// deselection
			setIsSelected(false)
		} else {
			// selection
			setIsSelected(true)
		}
	}

	useEffect(() => {
		// Make sure that the search field is removed from the excluded search fields when selected
		if (removedWhenSelected) {
			// Should be in exclusion if selected
			if (isSelected) {
				// Make sure field is excluded
				if (!excludedSearchFields.includes(searchField)) {
					addExcludedSearchField(searchField)
				}
			} else {
				// Should not be in exclusion if selected
				if (excludedSearchFields.includes(searchField)) {
					removeExcludedSearchField(searchField)
				}
			}
		} else {
			// Should NOT be in exclusion if selected
			if (isSelected) {
				// Make sure field is NOT excluded
				if (excludedSearchFields.includes(searchField)) {
					removeExcludedSearchField(searchField)
				}
			} else {
				// Should be in exclusion if not selected
				if (!excludedSearchFields.includes(searchField)) {
					addExcludedSearchField(searchField)
				}
			}
		}
	}, [isSelected])

	return (
		<FilterWrapper className={appendClassName(className)}>
			<Checkbox
				type='checkbox'
				label={label}
				id={`facet-${label}`}
				name={`facet-${label}`}
				checked={isSelected}
				onChange={handleToggle}
				style={{ fontWeight: isSelected ? 'bold' : 'normal' }}
			/>
		</FilterWrapper>
	)
}

export default SearchFieldFilter
