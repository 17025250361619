import type { SearchResult } from '@elastic/search-ui'
import {
	Accordion,
	AccordionContext,
	useAccordionButton,
} from 'react-bootstrap'
import { getRaw } from 'utils'
import CustomAccordionToggle from './CustomAccordionToggle'
import { Fragment, useContext } from 'react'
import { Link, Tag } from 'components/common'
import {
	getActiveStatus,
	handleNavigate,
	processText,
} from 'components/search/buckets/utils'
import { useTranslation } from 'react-i18next'
import { Content, ContentHeader } from './style'
import { useStoreContext } from 'context/StoreProvider'
import { useNavigate } from 'react-router-dom'

interface NLKResultViewProps {
	eventKey: string
	callback?: (eventKey: string) => void
	result: SearchResult
	searchTerm: string
	setSearchTermErstatt: (searchTerm: string) => void
}

const NLKResultView = ({
	result,
	eventKey,
	callback,
	searchTerm,
	setSearchTermErstatt,
}: SearchResult & NLKResultViewProps) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { activeEventKey } = useContext(AccordionContext)
	const { actions } = useStoreContext()

	const { updateCodeValue } = actions

	const decoratedOnClick = useAccordionButton(
		eventKey,
		() => callback && callback(eventKey)
	)

	const isAccordionOpen = activeEventKey?.includes(eventKey)

	const colLeftInfo = [
		{
			name: t('nlkPage.properties.component'),
			value: 'component',
		},
		{
			name: t('nlkPage.properties.system'),
			value: 'system',
		},
		{
			name: t('nlkPage.properties.propertyType'),
			value: 'property_type',
		},
		{
			name: t('nlkPage.properties.unit'),
			value: 'unit',
		},
		{
			name: t('nlkPage.properties.subjectArea'),
			value: 'subject_area',
		},
		{
			name: t('nlkPage.properties.validFrom'),
			value: 'valid_from',
		},
	]

	const colRightInfo = [
		{
			name: t('nlkPage.properties.componentSpesification'),
			value: 'component_specification',
		},
		{
			name: t('nlkPage.properties.systemSpesification'),
			value: 'system_specification',
		},
		{
			name: t('nlkPage.properties.propertyTypeSpesification'),
			value: 'property_type_specification',
		},
		{
			name: t('nlkPage.properties.group'),
			value: 'group',
		},
		{
			name: t('nlkPage.properties.subjectAreaSecondary'),
			value: 'secondary_subject_area',
		},
		{
			name: t('nlkPage.properties.validTo'),
			value: 'valid_to',
		},
	]

	const handleResultClick = () => {
		updateCodeValue(getRaw(result, 'replaced_by') as string)
		navigate(
			`${handleNavigate(getRaw(result, 'code_system') as string)}/?q=${
				getRaw(result, 'replaced_by') as string
			}`
		)
		setSearchTermErstatt(getRaw(result, 'replaced_by') as string)
	}
	return (
		<>
			<tr
				onClick={decoratedOnClick}
				className={isAccordionOpen ? 'active-border' : ''}
			>
				<td>
					<CustomAccordionToggle eventKey={eventKey} />
				</td>
				<td>{processText('name_norwegian', searchTerm, false, result)}</td>

				<td>{processText('code', searchTerm, false, result)}</td>

				<td>{processText('short_definition', searchTerm, false, result)}</td>
				<td align='right'>
					<Tag
						color={getActiveStatus(getRaw(result, 'active') as string)}
						text={getRaw(result, 'active') === 'true' ? 'Aktiv' : 'Inaktiv'}
					/>
				</td>
			</tr>

			<Accordion.Collapse
				eventKey={eventKey}
				className={`inner-table ${isAccordionOpen ? 'inner-table-active' : ''}`}
				as={'tr'}
			>
				<>
					<td>&nbsp;</td>
					<td colSpan={2} className='pb-5'>
						{colLeftInfo.map(
							(item, index) =>
								(getRaw(result, 'active') !== 'false' || index > 3) && (
									<Fragment key={`${item.name}-${index}`}>
										<ContentHeader>{item.name}</ContentHeader>

										<Content>
											{processText(item.value, searchTerm, false, result)}
										</Content>
									</Fragment>
								)
						)}
					</td>
					<td colSpan={2} className='pb-5'>
						{colRightInfo.map(
							(item, index) =>
								(getRaw(result, 'active') !== 'false' || index > 3) && (
									<Fragment key={`${item.name}-${index}`}>
										<ContentHeader>{item.name}</ContentHeader>

										<Content>
											{processText(item.value, searchTerm, false, result)}
										</Content>
									</Fragment>
								)
						)}
						{result.replaced_by &&
							result.replaced_by.raw &&
							result.code_system.raw && (
								<span
									className='d-flex gap-2 align-items-center pt-2'
									role='link'
									onClick={handleResultClick}
									onKeyDown={(event) => {
										if (event.key === 'Enter' || event.key === ' ') {
											handleResultClick()
										}
									}}
									tabIndex={0}
								>
									<span className=''>{t('general.replacedBy')}:</span>{' '}
									<Link
										text={getRaw(result, 'replaced_by') as string}
										href={`#`}
									/>
								</span>
							)}
					</td>
				</>
			</Accordion.Collapse>
		</>
	)
}

export default NLKResultView
