import { MutableRefObject, useRef, useState } from 'react'
import { historyService } from './history'

export interface StoreState {
	code: string | undefined
	codeSystem: string | undefined
	scrollRef: MutableRefObject<{ [key: string]: HTMLDivElement | null }>
	inputRef?: MutableRefObject<HTMLInputElement | null>
	hierarchyScrollRef: MutableRefObject<{
		[key: string]: HTMLDivElement | null
	}>
	showInactiveOnLoad: boolean
	excludedSearchFields: string[]
}

export interface StoreActions {
	updateCodeValue: (value?: string) => void
	updateCodeSystem: (system: string) => void
	updateScrollRef: (key: string, value: HTMLDivElement | null) => void
	updateInputRef?: (ref: HTMLInputElement | null) => void
	updateHierarchyScrollRef: (key: string, value: HTMLDivElement | null) => void
	updateShowInactiveOnLoad: (show: boolean) => void
	addExcludedSearchField: (field: string) => void
	removeExcludedSearchField: (field: string) => void
	updateExcludedSearchFields: (fields: string[]) => void
}

export const useStore = (): [StoreState, StoreActions] => {
	const [code, setCode] = useState<string | undefined>('')
	const [codeSystem, setCodeSystem] = useState<string | undefined>('')
	const scrollRef = useRef<{ [key: string]: HTMLDivElement | null }>({})
	const inputRef = useRef<HTMLInputElement | null>(null)
	const hierarchyScrollRef = useRef<{ [key: string]: HTMLDivElement | null }>(
		{}
	)
	const [showInactiveOnLoad, setShowInactiveOnLoad] = useState<boolean>(false)
	const [excludedSearchFields, setExcludedSearchFields] = useState<string[]>([])

	const updateCodeValue = (value?: string) => {
		if (value && codeSystem) {
			historyService.addToHistory(codeSystem as string, value)
		}
		setCode(value)
	}

	const updateCodeSystem = (system: string) => {
		setCodeSystem(system)
	}

	const updateScrollRef = (key: string, ref: HTMLDivElement | null) => {
		scrollRef.current[key] = ref
	}

	const updateInputRef = (ref: HTMLInputElement | null) => {
		inputRef.current = ref
	}

	const updateHierarchyScrollRef = (
		key: string,
		ref: HTMLDivElement | null
	) => {
		hierarchyScrollRef.current[key] = ref
	}

	const updateShowInactiveOnLoad = (show: boolean) => {
		setShowInactiveOnLoad(show)
	}

	const addExcludedSearchField = (field: string) => {
		setExcludedSearchFields([...excludedSearchFields, field])
	}

	const removeExcludedSearchField = (field: string) => {
		setExcludedSearchFields(
			excludedSearchFields.filter((excludedField) => excludedField !== field)
		)
	}

	const updateExcludedSearchFields = (fields: string[]) => {
		setExcludedSearchFields(fields)
	}

	const state: StoreState = {
		code,
		codeSystem,
		scrollRef,
		inputRef,
		hierarchyScrollRef,
		showInactiveOnLoad,
		excludedSearchFields,
	}

	const actions: StoreActions = {
		updateCodeValue,
		updateCodeSystem,
		updateScrollRef,
		updateInputRef,
		updateHierarchyScrollRef,
		updateShowInactiveOnLoad,
		addExcludedSearchField,
		removeExcludedSearchField,
		updateExcludedSearchFields,
	}

	return [state, actions]
}
