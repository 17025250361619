import { historyService } from 'context'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useStoreContext } from 'context/StoreProvider'
import {
	codesystemsEndpointName,
	fetchApi,
	getApiEndpoint,
	historyString,
	chapterString,
} from 'utils'
import { ClinicalParagraph } from 'components/search/results/ChapterResults/interface'
import {
	Button,
	Col,
	Row,
	TabContainer,
	Nav,
	Tab,
	Table,
} from 'react-bootstrap'
import Content from 'components/search/results/ChapterResults/Content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { AdmHistoryList } from '..'
import { SidebarContent } from 'components/search/buckets/SearchWithFilter/common/SearchResultLayout/style'
import { isMobile } from 'react-device-detect'

const HistoryList = () => {
	const { view } = useParams()
	const { state } = useStoreContext()
	const { codeSystem } = state
	const navigate = useNavigate()

	const [history, setHistory] = useState<ClinicalParagraph[]>([])

	let smallScreen = isMobile || window.innerWidth < 992

	const updateWindowDimensions = () => {
		smallScreen = isMobile || window.innerWidth < 992
	}
	window.addEventListener('resize', updateWindowDimensions)

	const fetchCodeInfo = async (codes: string[]) => {
		const endpoint = getApiEndpoint(codeSystem as string, codes.join('+'))
		const data = await fetchApi(endpoint)

		return data
	}

	const isAdm = codeSystem === codesystemsEndpointName.adm

	useEffect(() => {
		if (
			!codeSystem ||
			view !== historyString ||
			codeSystem === codesystemsEndpointName.adm
		)
			return

		const storedHistory = historyService.getCodeSystemHistory(codeSystem)

		if (!history) return
		// check if all stored already are in state
		if (
			storedHistory.every((c) => history.some((h) => h.codeValue === c)) &&
			history.length === storedHistory.length
		) {
			history.sort((a: ClinicalParagraph, b: ClinicalParagraph) => {
				return (
					storedHistory.indexOf(a.codeValue) -
					storedHistory.indexOf(b.codeValue)
				)
			})
			return
		}

		fetchCodeInfo(storedHistory).then((data) => {
			if (!data || !Array.isArray(data) || data.length === 0) return

			// ensure data is sorted the same as storedHistory
			data
				.sort((a: ClinicalParagraph, b: ClinicalParagraph) => {
					return (
						storedHistory.indexOf(a.codeValue) -
						storedHistory.indexOf(b.codeValue)
					)
				})
				.forEach((c: ClinicalParagraph) => {
					c.hierarchyLevel = 2
				})
			setHistory(data)
		})
	}, [codeSystem, view])

	const removeCode = (code: string) => {
		historyService.removeFromHistory(codeSystem as string, code)
		setHistory(history.filter((c) => c.codeValue !== code))
	}

	const clearHistory = () => {
		historyService.clearHistory(codeSystem as string)
		setHistory([])
	}

	const [key, setKey] = useState('0')

	return isAdm ? (
		<AdmHistoryList />
	) : history.length > 0 && codeSystem ? (
		<TabContainer activeKey={key} onSelect={(k) => setKey(k || '0')}>
			<Row>
				<SidebarContent $isAdm={isAdm} $size={'sm'}>
					<Nav>
						<Table striped hover className='p-0'>
							<thead>
								<tr>
									<th>Kode</th>
									<th>Fjern</th>
								</tr>
							</thead>
							<tbody>
								{history.map((paragraph: ClinicalParagraph, index: number) => (
									<tr
										onMouseEnter={() => setKey(`${index}`)}
										key={`history-list-item-${index}`}
									>
										<td
											onClick={() =>
												navigate(`${chapterString}/${paragraph.codeValue}/`)
											}
											style={{ cursor: 'pointer' }}
										>
											<span style={{ fontWeight: 'bold' }}>
												{paragraph.codeValue}
											</span>{' '}
											{paragraph.nameNorwegian || paragraph.nameEnglish}
										</td>
										<td>
											<Button
												variant='none'
												onClick={() => removeCode(paragraph.codeValue)}
											>
												<FontAwesomeIcon icon={faClose} />
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Nav>
				</SidebarContent>
				{!smallScreen && (
					<SidebarContent className={`d-flex `} $isAdm={isAdm} $size={'sm'}>
						<Tab.Content>
							{history.map((paragraph: ClinicalParagraph, index: number) => (
								<Tab.Pane
									eventKey={`${index}`}
									key={`history-item-details-${index}`}
								>
									<div className='m-3' />
									<Content
										paragraph={paragraph}
										codeSystem={codeSystem}
										activeCode={false}
									/>
								</Tab.Pane>
							))}
						</Tab.Content>
					</SidebarContent>
				)}
			</Row>
		</TabContainer>
	) : (
		<div>Ingen besøkte {isAdm ? 'kodeverk' : 'koder'}</div>
	)
}

export default HistoryList
