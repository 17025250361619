import { codesystemsEndpointName } from 'utils'

const labCodesystem = [
	codesystemsEndpointName.anatomisk,
	codesystemsEndpointName.tekstligeresultatverdier,
	codesystemsEndpointName.provemateriale,
	codesystemsEndpointName.undersokelsesmetode,
	codesystemsEndpointName.norpat,
]

const isLabCodesystem = (pathname: string) => {
	return labCodesystem.some((codesystem) => pathname.includes(codesystem))
}

export default isLabCodesystem
