import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Resizable } from 're-resizable'
import { LayoutWrapper, SidebarContent } from './style'
import { Hierarchy, HierarchyAdm } from 'components/common'
import { useStoreContext } from 'context/StoreProvider'
import { isMobile, isDesktop } from 'react-device-detect'
import { MultiColumnLayoutProps } from './interface'

const SearchResultLayout = ({
	results,
	isClinical,
	isAdm,
	dropdownHierarchy,
}: MultiColumnLayoutProps) => {
	const [sidebarWidth, setSidebarWidth] = useState(
		sessionStorage.getItem('sidebarWidth') || '400px'
	)

	const isMobileOrSmallScreen = isMobile || window.innerWidth < 992

	const handleResize = useCallback(() => {
		setSidebarWidth((currentWidth) => {
			if (isMobile || window.innerWidth < 992) {
				return '100%' // Adjust sidebar width on smaller screens dynamically
			}
			return currentWidth
		})
	}, [])

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [handleResize])

	useEffect(() => {
		sessionStorage.setItem('sidebarWidth', sidebarWidth)
	}, [sidebarWidth])

	return (
		<LayoutWrapper fluid>
			<div className='w-100 d-flex flex-row overflow-hidden'>
				{/* Start Mobile hierarchy overlay */}
				<div
					className='position-fixed'
					style={{
						zIndex: 2,
						width: '90%',
						height: '90vh',
						overflow: 'hidden',
						backgroundColor: 'rgba(0,0,0,0.25)',
						display:
							isMobileOrSmallScreen && dropdownHierarchy ? 'block' : 'none',
					}}
				></div>
				<div
					className=' bg-white position-fixed'
					style={{
						zIndex: 2,
						width: '90%',
						height: '65vh',
						overflow: 'scroll',
						border: '1px solid #6C757D',
						display:
							isMobileOrSmallScreen && dropdownHierarchy ? 'block' : 'none',
					}}
				>
					{!isAdm && (
						<SidebarContent
							className={`d-flex`}
							xs='auto'
							$isAdm={isAdm}
							$size={isClinical ? 'sm' : 'md'}
						>
							<Hierarchy />
						</SidebarContent>
					)}
					{isAdm && (
						<SidebarContent className={`d-flex `} $isAdm={isAdm} $size={'sm'}>
							<HierarchyAdm />
						</SidebarContent>
					)}
				</div>
				{/* End Mobile hierarchy overlay */}

				{isDesktop && window.innerWidth > 992 && (
					<Resizable
						size={{ width: sidebarWidth, height: '100%' }}
						onResizeStop={(e, direction, ref) => {
							setSidebarWidth(`${ref.offsetWidth}px`)
						}}
						maxWidth='60%'
						minWidth='10%'
						enable={{ right: true }}
						handleStyles={{
							right: {
								width: '4px',
								marginRight: '4px',
								height: `100%`,
								cursor: 'col-resize',
								background: '#6C757D',
							},
						}}
					>
						{!isAdm && (
							<SidebarContent
								xs='auto'
								$isAdm={isAdm}
								$size={isClinical ? 'sm' : 'md'}
							>
								<Hierarchy />
							</SidebarContent>
						)}

						{isAdm && (
							<SidebarContent
								xs='auto'
								$isAdm={isAdm}
								$size={isAdm ? 'md' : 'sm'}
							>
								<HierarchyAdm />
							</SidebarContent>
						)}
					</Resizable>
				)}

				<SidebarContent $isAdm={isAdm} $size={isClinical ? 'sm' : 'md'}>
					{results}
				</SidebarContent>
			</div>
		</LayoutWrapper>
	)
}

export default SearchResultLayout
