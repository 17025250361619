/* eslint-disable @typescript-eslint/lines-between-class-members */
import { codesystemsEndpointName, fetchApi, getApiEndpoint } from 'utils'
import { Chapter } from './interface'

export default class ChapterHandler {
	codeSystem: string
	code: string
	chapterData: Chapter | null
	pageParamBackward: number
	pageParamForward: number
	pageSize: number
	totalPages: number
	currentScrollPosition: number
	loading: boolean

	constructor(codeSystem: string, code: string) {
		this.codeSystem = codeSystem
		this.code = code
		this.chapterData = {} as Chapter
		this.pageParamBackward = 0
		this.pageParamForward = 0
		this.pageSize = 0
		this.totalPages = 0
		this.currentScrollPosition = 0
		this.loading = false
	}

	async initializeChapterData() {
		try {
			this.loading = true
			this.chapterData = await fetchApi(
				getApiEndpoint(this.codeSystem, this.code, {
					pageParam: null,
					isChapterView: true,
					includeInactive: this.codeSystem === codesystemsEndpointName.norpat,
				})
			)
			if (this.chapterData) {
				this.pageParamForward = this.chapterData.pageNumber + 1
				this.pageParamBackward = this.chapterData.pageNumber - 1
				this.totalPages = this.chapterData.totalPages
				if (this.pageParamBackward > 0 && this.totalPages > 1) {
					const newData = await fetchApi(
						getApiEndpoint(this.codeSystem, this.code, {
							pageParam: this.pageParamBackward,
							isChapterView: true,
							includeInactive:
								this.codeSystem === codesystemsEndpointName.norpat,
						})
					)
					if (newData) {
						this.chapterData = {
							...newData,
							data: [...newData.data, ...(this.chapterData?.data || [])],
						}
						this.pageParamBackward = newData.pageNumber - 1
					}
				}
				if (this.pageParamForward < this.totalPages) {
					const newData = await fetchApi(
						getApiEndpoint(this.codeSystem, this.code, {
							pageParam: this.pageParamForward,
							isChapterView: true,
							includeInactive:
								this.codeSystem === codesystemsEndpointName.norpat,
						})
					)
					if (newData) {
						this.chapterData = {
							...newData,
							data: [...(this.chapterData?.data || []), ...newData.data],
						}
						this.pageParamForward = newData.pageNumber + 1
					}
				}
				return this.chapterData
			}
		} catch (e) {
			console.error('Error fetching data: ' + e)
			return null
		} finally {
			this.loading = false
		}
	}

	async fetchMoreData(direction: 'prev' | 'next') {
		if (this.chapterData === null || this.loading) {
			return
		}
		if (!this.morePagesAvailable(direction)) return

		try {
			this.loading = true
			const newData = await fetchApi(
				getApiEndpoint(this.codeSystem, this.code, {
					pageParam:
						direction === 'prev'
							? this.pageParamBackward
							: this.pageParamForward,
					isChapterView: true,
					includeInactive: this.codeSystem === codesystemsEndpointName.norpat,
				})
			)
			if (newData) {
				if (direction === 'prev') {
					this.chapterData = {
						...newData,
						data: [...newData.data, ...(this.chapterData?.data || [])],
					}
					this.pageParamBackward = newData.pageNumber - 1
				}
			}
			if (direction === 'next') {
				this.chapterData = {
					...newData,
					data: [...(this.chapterData?.data || []), ...newData.data],
				}
				this.pageParamForward = newData.pageNumber + 1
			}
			this.totalPages = this.chapterData!.totalPages
			this.loading = false
		} catch (e) {
			console.error('Error fetching data: ' + e)
			this.loading = false
		}
		return this.chapterData
	}

	public morePagesAvailable(direction: 'prev' | 'next') {
		if (direction === 'prev' && this.pageParamBackward < 1) {
			return false
		} else if (direction === 'next' && this.pageParamForward > this.totalPages)
			return false
		return true
	}
}
