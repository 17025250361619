import type { SearchResult } from '@elastic/search-ui'
import { TableRow } from '../style'
import { Col } from 'react-bootstrap'
import { getRaw, getSnippet } from 'utils'
import {
	getStatusText,
	getTagColor,
	processText,
	highlightSearchTerm,
} from 'components/search/buckets/utils'
import { Tag } from 'components/common'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

const AdmResultView = ({
	result,
	searchTerm,
	showCodes = false,
}: SearchResult) => {
	const { t } = useTranslation()
	return (
		<>
			<TableRow role='row' className='gap-1 gap-md-3 w-100 flex-nowrap'>
				<Col role='cell' className='p-0' xs={1}>
					{getRaw(result, 'compact_code') &&
						getRaw(result, 'compact_code')?.substring(0, 4)}
				</Col>
				<Col role='cell' xs={3} className='p-0 text-break'>
					{getRaw(result, 'name_norwegian') && (
						<>{processText('name_norwegian', searchTerm, false, result)}</>
					)}
				</Col>
				<Col role='cell' xs={2} className='p-0'>
					{getRaw(result, 'owner_name') && (
						<>{processText('owner_name', searchTerm, false, result)}</>
					)}
				</Col>
				<Col role='cell' xs={3} md={2} className='p-0'>
					{getRaw(result, 'parent_code') &&
						parse(
							highlightSearchTerm(
								getSnippet(result, 'parent_code')?.slice(4) as string,
								searchTerm
							)
						)}
				</Col>
				<Col role='cell' xs={2} className='p-0'>
					{getRaw(result, 'text_max_60') &&
						parse(
							highlightSearchTerm(
								getSnippet(result, 'text_max_60')?.slice(4) as string,
								searchTerm
							)
						)}
				</Col>
				<Col role='cell' xs={1} className='d-flex p-lg-0'>
					{getRaw(result, 'status_text') && (
						<Tag
							color={getTagColor(getRaw(result, 'status_text') as string)}
							text={getStatusText(getRaw(result, 'status_text') as string)}
						/>
					)}
				</Col>
			</TableRow>
			{showCodes &&
				getSnippet(result, 'code') !== undefined &&
				result.code.snippet?.indexOf('<em>') !== -1 && (
					<Col md={12} role='row' className='border-left ps-2 mt-2'>
						<span className='fw-bold '>{t('general.codes')}: </span>
						<span className='fs-6'>
							{parse(
								highlightSearchTerm(
									getSnippet(result, 'code') as string,
									searchTerm
								) as string
							)}
						</span>
					</Col>
				)}
		</>
	)
}

export default AdmResultView
